.banner {
  height: 100vh;
}
.right-section {
  background: linear-gradient(to right, #fff 50%, #2a79bd 50%);
  height: 100%;
}
.right-section img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left;
}
.banner form input:focus {
  outline: none;
}
@media screen and (max-width: 991px) {
  .right-section {
    display: none;
  }
  .banner {
    height: 100%;
    padding: 100px 0px 0px;
  }
}
