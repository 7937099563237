.dropdown:hover .dropdown-menu {
  display: block;
  z-index: 999;
}
.bottom-bar {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
/* @keyframes popupOpen {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes popupClose {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
} */
@keyframes popupAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.popup-custom {
  animation: popupAnimation 0.8s ease forwards;
}

.expandQuill .ql-container {
  height: 250px;
}
/* .popupClose {
  animation: popupClose 0.3s ease-out forwards;
} */
@media screen and (max-width: 1024px) {
  .mob-section .bottom-bar {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 991px) {
  .mob-section {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .customComposeEmail .ql-toolbar.ql-snow .ql-formats {
    margin-right: 8px !important;
  }
  .customComposeEmail .ql-snow.ql-toolbar button {
    padding: 3px;
    width: 22px;
  }
  .customComposeEmail .ql-snow .ql-icon-picker {
    width: 28px;
  }
  .customComposeEmail .ql-snow .ql-picker.ql-font {
    width: 84px;
  }
  .customComposeEmail .ql-snow .ql-picker.ql-size {
    width: 68px;
  }
  .customComposeEmail .ql-snow .ql-picker-label {
    padding-left: 0px;
  }
  .customComposeEmail .ql-snow .ql-color-picker .ql-picker-label,
  .customComposeEmail .ql-snow .ql-icon-picker .ql-picker-label {
    padding: 2px;
  }
  .customComposeEmail .ql-toolbar.ql-snow {
    padding: 4px;
  }
}

@media screen and (max-width: 480px) {
  .customComposeEmail .ql-snow .ql-color-picker .ql-picker-label svg,
  .customComposeEmail .ql-snow .ql-icon-picker .ql-picker-label svg {
    width: 15px;
  }
  .customComposeEmail .ql-snow.ql-toolbar button svg,
  .customComposeEmail .ql-snow .ql-toolbar button svg {
    width: 15px;
  }
  .customComposeEmail .ql-toolbar.ql-snow .ql-formats {
    margin-right: 3px !important;
  }
  .customComposeEmail .ql-snow.ql-toolbar button {
    padding: 3px;
    width: 22px;
  }
  .customComposeEmail .ql-snow .ql-icon-picker {
    width: 22px;
  }
  .customComposeEmail .ql-snow .ql-picker.ql-font .ql-picker-label::before,
  .customComposeEmail .ql-snow .ql-picker.ql-font .ql-picker-item::before,
  .customComposeEmail .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .customComposeEmail .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    font-size: 12px;
  }
  .customComposeEmail .ql-snow .ql-color-picker,
  .customComposeEmail .ql-snow .ql-icon-picker {
    width: 17px;
    height: 17px;
  }
}
