@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    overflow: hidden;
}

@media (max-width: 991px){
    body{
        overflow: auto;
    }
}