.item-active svg,
.item-active p {
  color: #fff;
}
.item-active {
  background-color: rgb(42 121 189) !important;
}
.item:hover {
  background-color: #e4f3ff !important;
}
.custom-checkbox input[type="checkbox"] {
  display: none;
  cursor: pointer;
}
.custom-checkbox input[type="checkbox"] + label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #dedede;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}
.custom-checkbox input[type="checkbox"]:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 11px;
  left: 7px;
  width: 5px;
  height: 10px;
  border: 1px solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.single-inbox-section {
  height: 804px;
  overflow-y: scroll;
}
.cs-check .checked\:border-gray-900:checked {
  border-color: rgb(255 255 255);
}
