.search {
  border-radius: 0px 0.375rem 0.375rem 0px;
}
.search-bar {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.highlight-btn {
  box-shadow: rgb(42 121 189 / 50%) 0px 4px 16px,
    rgb(42 121 189 / 0%) 0px 8px 32px;
  border: none !important;
}
.highlight-btn a:hover {
  border: none !important;
}

@media screen and (max-width: 767px) {
  .aside {
    width: 100%;
  }
}

.sidebar {
  border-right: 1px solid #eee;
  height: 100vh;
  background: #fff;
  box-shadow: 0px 0px 10px #eee;
}
.list {
  height: calc(100vh - 122px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* .sidebar ul li a {
  transition: 1.2s ease-in-out;
} */
.list a {
  text-align: left;
}
.list ul li {
  margin: 1px 0px;
}
.list ul li:last-child {
  margin: 0px;
}
.sidebar .list a:hover {
  background-color: #2a79bd;
  color: #fff;
  font-weight: 600;
  box-shadow: rgb(42 121 189 / 50%) 0px 4px 16px,
    rgb(42 121 189 / 0%) 0px 8px 32px;
}
.active_link {
  background-color: #2977ba;
  color: #fff;
  font-weight: 600;
  box-shadow: rgb(42 121 189 / 50%) 0px 4px 16px,
    rgb(42 121 189 / 0%) 0px 8px 32px;
}
.active_link-new {
  background-color: #2977ba;
  color: #fff;
}
.hover-min-width:hover {
  background-color: #2977ba;
  color: #fff;
}
.sidebar .list a:active {
  box-shadow: rgb(42 121 189 / 50%) 0px 4px 16px,
    rgb(42 121 189 / 0%) 0px 8px 32px;
}
.sidebar-inner {
  height: 100vh;
  transition: 1s ease-in-out;
}
.highlight-min-width {
  height: 56px;
}

@media screen and (max-width: 767px) {
  .sidebar {
    position: absolute;
    width: 0px;
    left: 0px;
    background: #fff;
    box-shadow: 0px 0px 10px #eee;
    top: 0;
    border-right: 1px solid #ccc;
    border-radius: 0;
    z-index: 999;
  }
  .sidebar-inner {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-right: 1px solid #ccc;
    border-radius: 0;
    z-index: 999;
    width: 260px;
  }
  .sidebar-inner h1 {
    justify-content: start;
    margin-left: 0;
    padding: 0px;
  }
  .sidebar-head {
    padding: 0px 10px;
  }
}
